import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import WebView from './App';
import MobileView from './MobileApp';
import * as serviceWorker from './serviceWorker';

import { createStore,combineReducers ,applyMiddleware} from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import LeaveReducers from './store/reducers/LeaveReducers'
import payrollReducers from './store/reducers/PayrollReducers'

import EmployeeProfileReducers from './store/reducers/EmployeeProfileReducer'
import EmployeeSalaryReducer from './store/reducers/EmployeeSalaryReducer'
import AttendanceReducers from './store/reducers/AttendanceReducers'

const rootReducer = combineReducers({
   pr:payrollReducers,
   lr:LeaveReducers,
   attendanceReducers:AttendanceReducers,
   EPR : EmployeeProfileReducers,
   ESR : EmployeeSalaryReducer
})
 
const store = createStore(rootReducer,applyMiddleware(thunk))
const changeView = () =>{
    var w = window.innerWidth;
    if(w<780){
        ReactDOM.render(<MobileView/>, document.getElementById('root'));
    }else{
        ReactDOM.render(<Provider store={store}><WebView /></Provider>, document.getElementById('root'));
    }
}
changeView();

serviceWorker.unregister();

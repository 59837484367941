
import axios from 'axios';
import * as URL from '../../constants/URL'
export const FECTH_DATA_FROM_EMPLOYEE_PROFILE_API = 'FECTH_DATA_FROM_EMPLOYEE_PROFILE_API'
export const REMOVE_LEAVE = 'REMOVE_LEAVE'

export const setLeaveData = (data) =>{
    return  { 
        type:FECTH_DATA_FROM_EMPLOYEE_PROFILE_API,
        value:data
     }
}

export const getEmployeeProfileData = (employee_id) =>{
    return dispatch=>{
        var postdata = {
            "user_id" : employee_id,
        }
        axios.post(URL.getLeave,postdata)
        .then(function (response) {
            if(response && response.data && response.data.status===200){
                console.log("Employee Leave Data");
                
                console.log(response.data);
                
                dispatch( 
                    setLeaveData(response.data.doc)
                )
            }
            else if(response && response.data && response.data.status==="404"){
                
            }
        })
    }
}

export const removeLeave = (leave_id, index) =>{
    return dispatch=>{
        var postdata = {
            "id":leave_id
        }
        var r = window.confirm("Are you sure to delete the leave");
        if (r == true) {
            if(postdata.id!=null || postdata.id!="" || postdata.id!=undefined){
                axios.post(URL.removeLeave,postdata)
                .then(function (response) {
                    if(response && response.data && response.data.status==="200"){
                        alert('Leave deleted Successfully');
                        dispatch( {
                            type:REMOVE_LEAVE,
                            value:{id:leave_id, index : index}
                        })
                    }
                    else if(response && response.data && response.data.status==="404"){
                        alert('Error in Deleting Leaves');
                    }
                })
            }
        }
    }
}
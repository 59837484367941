import React, {Component,Suspense,lazy} from 'react';
import {BrowserRouter,Route} from 'react-router-dom';
// import './App.css'
const LoginLayout = lazy(() => import('./WebView/Component/Login/Login'));
const Dashboard = lazy(() => import('./WebView/Component/Drawer/Drawer'));
const AppliedLeavesMail = lazy(() => import('./WebView/Component/AppliedLeaves/AppliedLeavesMail'));


class App extends Component {
  

  render(){
    
    return (
      <BrowserRouter>
        <React.Fragment >
          <Route path="/" exact
              render={() => <Suspense fallback={<div>Loading...</div>}>
                <LoginLayout/>
                </Suspense>
              }
          />

          
       
          <Route path="/dashboard" exact
              render={() => <Suspense fallback={<div>Loading...</div>}>
                <Dashboard/>
                </Suspense>
              }
          />

          <Route path="/employees" exact
              render={() => <Suspense fallback={<div>Loading...</div>}>
                <Dashboard/>
                </Suspense>
              }
          />

           <Route path="/employees-payroll" 
              render={() => <Suspense fallback={<div>Loading...</div>}>
                <Dashboard/>
                </Suspense>
              }
          />

          <Route path="/add-employee" exact
              render={() => <Suspense fallback={<div>Loading...</div>}>
                <Dashboard/>
                </Suspense>
              }
          />
          <Route path="/employee-details" exact
              render={() => <Suspense fallback={<div>Loading...</div>}>
                <Dashboard/>
                </Suspense>
              }
          />
          <Route path="/applied-leaves" exact
              render={() => <Suspense fallback={<div>Loading...</div>}>
                <Dashboard/>
                </Suspense>
              }
          />
             <Route path="/applied-leaves-mail" 
              render={() => <Suspense fallback={<div>Loading...</div>}>
                <AppliedLeavesMail/>
                </Suspense>
              }
          />
          <Route path="/payroll" exact
              render={() => <Suspense fallback={<div>Loading...</div>}>
                <Dashboard/>
                </Suspense>
              }
          />
          <Route path="/payroll-details"
              render={() => <Suspense fallback={<div>Loading...</div>}>
                <Dashboard/>
                </Suspense>
              }
          />

          <Route path="/attendance"
              render={() => <Suspense fallback={<div>Loading...</div>}>
                <Dashboard/>
                </Suspense>
              }
          />

          <Route path="/attendance-month"
              render={() => <Suspense fallback={<div>Loading...</div>}>
                <Dashboard/>
                </Suspense>
              }
          />
        </React.Fragment>

      </BrowserRouter>
    );
  }
}

export default App;
import {FECTH_DATA_FROM_EMPLOYEE_PROFILE_API, REMOVE_LEAVE} from '../actions/EmployeeProfileAction'
const initialState = {
    doc:null
}

const reducer = (state = initialState, action) =>{
    switch (action.type){
        case FECTH_DATA_FROM_EMPLOYEE_PROFILE_API:
            return({doc:action.value})
        case REMOVE_LEAVE: 
            var copyOfLeaveData = state;
            copyOfLeaveData.doc.splice(action.value.index,1);
            return({doc:copyOfLeaveData.doc});      
        default:
            return state;    
    }
}

export default reducer;
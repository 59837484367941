
import axios from 'axios';
import * as URL from '../../constants/URL'
export const FECTH_DATA_FROM_LEAVE_APPLICATION_API = 'FECTH_DATA_FROM_LEAVE_APPLICATION_API'
export const CHNAGE_STATUS = 'CHNAGE_STATUS'

export const setData = (data) =>{

    return  { 
        type:FECTH_DATA_FROM_LEAVE_APPLICATION_API,
        value:data
     }
}

export const chnageStatus = (data,UserData,status,index) =>{
    return dispatch=>{
        var postdata = {
            "leave_id":data._id,
            "parentLeaveId" : data.parentLeaveId,
            "status":status,
            "applied_to":data.applied_to,
            "applied_from":data.applied_from,
            "type":data.type,
            "leavesApplied":data.leavesApplied,
            "user_id":UserData._id,
            "employee_id":UserData.employee_id,
            "full_name":UserData.full_name
        }
        var r = window.confirm("Are You Sure to " + status + " this Leave");
        if (r == true) {
            axios.post(URL.LeaveStatusChange,postdata)
            .then(function (response) {
                if(response && response.data && response.data.status === 200){
                    console.log(response.data);
                    dispatch( {
                        type:CHNAGE_STATUS,
                        value:{id:data._id,status:status,index}
                    })
                }
            })
        }
     }
}

export const getLeaveApplied = (status) =>{
    return dispatch=>{
        var postdata = {
            "status" : status
        }
        axios.post(URL.getLeaveApplied,postdata)
        .then(function (response) {
            dispatch( 
                setData(response.data.doc)
            )
        }).catch(e=>{
            console.log('catch block');
            return;
        })
    }
}


import {FECTH_DATA_FROM_LEAVE_APPLICATION_API,CHNAGE_STATUS} from '../actions/LeaveActions'

const initialState = {
    doc:null
}

const reducer = (state = initialState, action) =>{


    switch (action.type){
        case FECTH_DATA_FROM_LEAVE_APPLICATION_API:  
            console.log('slots actions',action);
             return({doc:action.value})

        case CHNAGE_STATUS: 
        console.log('slots actions',action);
        var copyOfstate = state;
        var data =  copyOfstate.doc.find(element=> element._id === action.value.id)
        data.status = action.value.status;
        console.log(data);
        
        return({doc:copyOfstate.doc})

        default:
            return state;    
    }

    

}

export default reducer;
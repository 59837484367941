import React, {Component,Suspense,lazy} from 'react';
import {BrowserRouter,Route} from 'react-router-dom';

const LoginLayout = lazy(() => import('./MobileView/Component/Login/Login'));
const TopNav = lazy(() => import('./MobileView/Component/TopNav/TopNav'));
const NFC = lazy(() => import('./MobileView/Component/NFCLogin/NFCLogin'));

class App extends Component {
  render(){
  return (
    <BrowserRouter>
        <React.Fragment >
            <Route path="/" exact
                render={() => <Suspense fallback={<div>Loading...</div>}>
                        <LoginLayout />
                    </Suspense>
                }
            />
            <Route path="/dashboard" exact
              render={() => <Suspense fallback={<div>Loading...</div>}>
                      <TopNav/>
                </Suspense>
              }
          />
          <Route path="/attendance" exact
              render={() => <Suspense fallback={<div>Loading...</div>}>
                      <TopNav/>
                </Suspense>
              }
          />
          <Route path="/salary-slip" exact
              render={() => <Suspense fallback={<div>Loading...</div>}>
                      <TopNav/>
                </Suspense>
              }
          />
          <Route path="/leave" exact
              render={() => <Suspense fallback={<div>Loading...</div>}>
                      <TopNav/>
                </Suspense>
              }
          />
          <Route path="/applied-leaves" exact
              render={() => <Suspense fallback={<div>Loading...</div>}>
                      <TopNav/>
                </Suspense>
              }
          />
          <Route path="/meetings" exact
              render={() => <Suspense fallback={<div>Loading...</div>}>
                      <TopNav/>
                </Suspense>
              }
          />
          <Route path="/survey" exact
              render={() => <Suspense fallback={<div>Loading...</div>}>
                      <TopNav/>
                </Suspense>
              }
          />
          <Route path="/profile" exact
              render={() => <Suspense fallback={<div>Loading...</div>}>
                      <TopNav/>
                </Suspense>
              }
          />

        <Route path="/NFC" exact
                    render={() => <Suspense fallback={<div>Loading...</div>}>
                            <NFC/>
                      </Suspense>
                    }
                />
                
        </React.Fragment>
    </BrowserRouter>
  );
}

}

export default App;
import {FECTH_DATA_FROM_EMPLOYEE_SALARY_API, REMOVE_SALARY, ADD_SALARY} from '../actions/EmployeeSalaryAction'
const initialState = {
    doc:null
}

const reducer = (state = initialState, action) =>{
    switch (action.type){
        case FECTH_DATA_FROM_EMPLOYEE_SALARY_API:
            return({doc:action.value})
        case REMOVE_SALARY: 
            var copyOfSalaryData = state;
            copyOfSalaryData.doc.splice(action.value.index,1);
            return({doc:copyOfSalaryData.doc});
        default:
            return state;    
    }
}

export default reducer;

import axios from 'axios';
import * as URL from '../../constants/URL'
export const FECTH_DATA_FROM_EMPLOYEE_SALARY_API = 'FECTH_DATA_FROM_EMPLOYEE_SALARY_API'
export const REMOVE_SALARY = 'REMOVE_SALARY'
export const ADD_SALARY = 'ADD_SALARY';

export const setSalaryData = (data) =>{
    return  { 
        type:FECTH_DATA_FROM_EMPLOYEE_SALARY_API,
        value:data
     }
}

export const getEmployeeSalaryData = (employee_id) =>{
    return dispatch=>{
        var postdata = {
            "user_id" : employee_id,
        }
        axios.post(URL.getSalaryStructure,postdata)
        .then(function (response) {
            if(response && response.data && response.data.status===200){
                console.log('Salary Feth Data Hit response indside 200 responese');
                console.log(response.data);
                
                dispatch( 
                    setSalaryData(response.data.doc)
                )
            }
            else if(response && response.data && response.data.status==="404"){
                dispatch( 
                    setSalaryData(null)
                )
            }
        })
    }
}
export const removeSalary = (salary_id, index) =>{
    return dispatch=>{
        var postdata = {
            "id" : salary_id,
        }
        var r = window.confirm("Are you sure to delete this Salary?");
        if (r == true) {
            if(postdata.user_id!=null || postdata.user_id!="" || postdata.user_id!=undefined){
                axios.post(URL.removeSalaryStructure,postdata)
                .then(function (response) {
                    console.log(response);
                    if(response && response.data && response.data.status==="200"){
                        alert('Salary deleted Successfully');
                        // mainArray.splice(index,1);
                        // props.setMainArray({doc:mainArray});
                        dispatch( {
                            type:REMOVE_SALARY,
                            value:{id:salary_id, index : index}
                        })
                    }
                    else if(response && response.data && response.data.status==="404"){
                        alert('Error in Deleting Payroll');
                    }
                })
            }
        }
    }
}
export const addSalary = (employee_id, basic, hra, others) =>{
    var postdata = {
        "user_id" : employee_id,
        "basic": basic,
        "hra": hra,
        "other": others
    }
    if(postdata.user_id === '' || postdata.basic === '' || postdata.hra === null || postdata.other === '' || postdata.other === null
        || postdata.basic === null){
            alert('Error');
    }
    else{
        console.log(postdata);
        axios.post(URL.AddSalaryStructure,postdata)
        .then(function (response) {
            console.log(response);
            if(response && response.data && response.data.status==="200"){
                console.log(employee_id);
                
                getEmployeeSalaryData(employee_id);
                // dispatch( {
                //     type:ADD_SALARY,
                //     value: 'Salary Added'
                // })
            }
            else if(response && response.data && response.data.status==="404"){
                alert('Someting went wrong');
            }
        })
    }
}
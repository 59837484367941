
import axios from 'axios';
import * as URL from '../../../src/constants/URL'
export const FECTH_DATA_FROM_PAYROLL_API = 'FECTH_DATA_FROM_PAYROLL_API'
export const FECTH_DATA_FROM_ATTENDANCE = 'FECTH_DATA_FROM_ATTENDANCE'


export const setData = (data) =>{

    return  { 
        type:FECTH_DATA_FROM_PAYROLL_API,
        value:data
     }
}

export const setDataToAttendance = (data) =>{

    return  { 
        type:FECTH_DATA_FROM_ATTENDANCE,
        value:data
     }
}

export const fetchDataFromAttendance = (params) =>{
    
    console.log(params);
    

    return dispatch=>{

        //getMonthCode(params[3])
        var postdata={
            "user_id":params[4],
            "month_code" :getMonthCode(params[3])
        }
        axios.post(URL.getSalary,postdata)
        .then(function (response) {
            console.log(response);
          //  setSalartStateObj({doc:null})
            if(response.data.status ===200){
               dispatch( setDataToAttendance(response.data.doc))
            }else{

            }
        })


      

    }

}

export const payrollAction = () =>{

    console.log('hit recevie');
    

    return dispatch=>{

        axios.post(URL.loadProject,null)
        .then(function (response) {
            console.log(response);
            dispatch( 
               setData(response.data.doc)
            )
        
        })


      

    }

}


var getMonthCode = (UNIX_timestamp) =>{
    var a = new Date(parseInt(UNIX_timestamp));
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time =  month + year
    return time;
  }
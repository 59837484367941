export const BaseUrl = 'https://tankha.headsupcorporation.com/api/';
//export const BaseUrl = 'https://hrapi.altspace.work/api/';

export const Login = BaseUrl+'login_user';
export const GetAttendanceStatus = BaseUrl+'get_attendance_status';
export const punch_in = BaseUrl + 'punch_in';
export const punch_out = BaseUrl + 'punch_out';
export const set_rating = BaseUrl + 'hr/setRating';
export const leave = BaseUrl + 'leave_apply';
export const getAttendance = BaseUrl + 'getAttendanceUser';
export const getPunchInToday = BaseUrl + 'getPunchInToday';
export const getLeave = BaseUrl + 'getLeave';
export const CreateLeave = BaseUrl + 'hr/CreateLeave';
export const getLeavesApplied = BaseUrl + 'getleavesApplied';
export const getUsers = BaseUrl + 'hr/getUsers';
export const getLeaveApplied = BaseUrl + 'hr/getLeaveApplied';
export const LeaveStatusChange = BaseUrl + 'hr/LeaveStatusChange';
export const getAttendaceCSV = BaseUrl + 'hr/getAttendaceCSV.csv';
export const created_user = BaseUrl + 'created_user';
export const AddSalaryStructure = BaseUrl + 'hr/AddSalaryStructure';
export const getSalaryStructure = BaseUrl + 'hr/getSalaryStructure';
export const CraeteSalary = BaseUrl + 'hr/CraeteSalary';
export const getPunchInOfTheday = BaseUrl + 'hr/getPunchInOfTheday';
export const removeLeave = BaseUrl + 'hr/removeLeave';
export const removeSalaryStructure = BaseUrl + 'hr/removeSalaryStructure';
export const createProjectSalary = BaseUrl + 'hr/createPayroll';
export const loadProject = BaseUrl + 'hr/getProjects';
export const updateUser = BaseUrl + 'hr/updateUser';
export const CreateMeeting = BaseUrl + 'attendance/CreateMeeting';
export const getMeeting = BaseUrl + 'attendance/getMeeting';
export const updateMeeting = BaseUrl + 'attendance/UpdateMeeting';
export const removeMeeting = BaseUrl + 'attendance/removeMeeting';
export const getSalary = BaseUrl + 'hr/getSalary';
export const calculateSalary = BaseUrl + 'hr/calculateSalary';
export const updatePermonthSalary = BaseUrl + 'hr/updatePermonthSalary';

export const getFinalCSV = BaseUrl + 'hr/getFinalCSV';






//attendance/getMeeting

import {FECTH_DATA_FROM_PAYROLL_API} from '../actions/PayrollActions'

const initialState = {
    data:{doc:null}
}

const reducer = (state = initialState, action) =>{


    switch (action.type){
        case FECTH_DATA_FROM_PAYROLL_API:  
            console.log('slots actions',action);
            return({doc:action.value})
        default:
            return state;    
    }

    

}

export default reducer;